import { css } from '@emotion/css';

import { SPACING, COLORS2, font } from '^/constants/commonStyles';
import CancellationAndRefundOntraPOD from '^/pages/Booking/components/DriverDetails/components/CancellationAndRefund/CancellationAndRefund.Ontra.POD';
import ReservationConfirm from '^/pages/Booking/onlinetravel/DriversDetails/components/ReservationConfirm';

import { ReservationStatusBadge } from '../desktop/ReservationStatusBadge';

import ReservationDetailsBillDesktop from './Bill/Bill.Desktop';
import ReservationDetailsDriverInfo from './DriverInfo';
import Layout from '^/components/Layout';

const ReservationDetailDesktop = ({ resData, setShowCancelConfirmPopup, isCancelDisabled }) => {
  const totalOptionsFee = resData.option_info_list.reduce(
    (acc, option) => acc + option.foreign_price * option.quantity,
    0,
  );
  return (
    <div
      id="ReservationDetailsPageDesktopRoot"
      className={[
        css`
          height: 100%;
          /* overflow-y: scroll; */
          @media (max-width: 1280px) {
            padding-left: 24px;
            padding-right: 24px;
          }
          /* (100 % - 1280) / 2, but with visual offet (+20px ) */
          padding-left: calc(50% - 660px);
          padding-right: calc(50% - 660px);
          display: grid;
          gap: ${SPACING.spacing24};
          padding-top: 60px;
          grid-template-columns: 1fr max-content;
          grid-template-rows: 1fr min-content;
          grid-template-areas:
            'content floatingbill'
            'footer footer';
        `,
        // borders.DEBUG_blueBorderDashed,
      ].join(' ')}
    >
      <div
        className={[
          css`
            grid-area: content;
          `,
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            width: 100%;
          `}
        >
          <h1 className={[font.Title1].join(' ')}>예약 내역 상세</h1>
          <ReservationStatusBadge status={resData.booking_info.status} />
        </div>

        <h2
          className={[
            font.Title2,
            css`
              margin-top: 60px;
            `,
          ].join(' ')}
        >
          차량 및 일정
        </h2>
        <ReservationConfirm
          data={resData}
          options={resData.option_info_list}
          isTitle={false}
          isDetail
        />
        <div
          className={[
            css`
              margin-top: ${SPACING.spacing48};
              background-color: ${COLORS2.sub.MrtGray2};
              height: 1px;
              width: 100%;
            `,
          ].join(' ')}
        />
        <h2
          className={[
            font.Title2,
            css`
              margin-top: ${SPACING.spacing48};
            `,
          ].join(' ')}
        >
          운전자 정보
        </h2>
        <ReservationDetailsDriverInfo driverInfo={resData.driver_info} />
        {resData?.plan_info.cancel_policy.length > 0 && (
          <>
            <div
              className={[
                css`
                  margin-top: ${SPACING.spacing48};
                  background-color: ${COLORS2.sub.MrtGray2};
                  height: 1px;
                  width: 100%;
                `,
              ].join(' ')}
            />
            <h2
              className={[
                font.Title2,
                css`
                  margin-top: ${SPACING.spacing48};
                `,
              ].join(' ')}
            >
              취소 및 환불규정
            </h2>
            <div className="mt-4 rounded-[10px] bg-secondary-gray-light p-4 text-default font-light leading-5 mobile:text-small">
              <CancellationAndRefundOntraPOD cancelPolicy={resData.plan_info.cancel_policy} />
            </div>
          </>
        )}
      </div>
      <div
        className={[
          css`
            position: sticky;
            top: 100px;
            height: fit-content;
            margin-bottom: 380px;
            grid-area: floatingbill;

            box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            background-color: white;

            padding-top: ${SPACING.spacing24};
            padding-left: ${SPACING.spacing24};
            padding-right: ${SPACING.spacing24};
            padding-bottom: ${SPACING.spacing16};
            width: 424px;
          `,
        ].join(' ')}
      >
        <ReservationDetailsBillDesktop
          resData={resData}
          totalOptionsFee={totalOptionsFee}
          setShowCancelConfirmPopup={setShowCancelConfirmPopup}
          isCancelDisabled={isCancelDisabled}
        />
      </div>
    </div>
  );
};

export default ReservationDetailDesktop;
