/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import { css } from '@emotion/css';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from '^/assets/icons/ic_info_black.svg';
import {
  borders,
  COLORS,
  SPACING,
  MARGINS,
  PADDINGS,
  COLORS2_AS_BACKGROUND,
  COLORS2_AS_FOREGROUND,
  font2,
} from '^/constants/commonStyles';
import { isReservationCancelDisabled } from '^/pages/ReservationList/isReservationDisabled';
import { ReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';
import { formatKRWWonAmount } from '^/util/formatKRWWonAmount';
import { parseFloatNumberOrNumberString } from '^/util/zod/numberFromString';

import { HoverToReveal } from '../../../../commonComponents/ui/HoverToReveal';
import { AdditionalFees2 } from '../../AdditionalFees2';
import { PPDOnsitePaymentHoverMessage } from '../../onsiteInfoMessages';

import {
  flexRightmostItemRow,
  flexSpacedRowWithVerticalCenter,
} from './ReservationDetailDesktop.commonStuff';
import { formatDateString } from '^/util/dateFormat';
import dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';

// dayjs.extend(utc);
// dayjs.extend(timezone);

function isCancelledAndPPDReservation(data: ReservationDetailed) {
  if (data.res_status !== 'canceled' || data.pay_type !== 'PPD') {
    throw new Error('DummyErrorForTypeFiltering');
  }
  return data;
}

export const ReservationDetailsBillPPDCancelled: React.FC<{
  data: ReturnType<typeof isCancelledAndPPDReservation>;
}> = observer(function ReservationDetailsBillPPDCancelled({ data }) {
  const { t } = useTranslation();

  const paymentTime = formatDateString(data.date_request, 'YYYY.MM.DD HH:mm (z)', 'Asia/Seoul');
  const cancelTime = formatDateString(data.date_cancel, 'YYYY.MM.DD HH:mm (z)', 'Asia/Seoul');

  return (
    <div className={[css``, COLORS2_AS_FOREGROUND.main.MrtBlack].join(' ')}>
      <h3 className={[font2.Title3].join(' ')}>{t('total')}</h3>

      <div
        id="separator1"
        className={[
          MARGINS.TOP_AND_BOTTOM.spacing16,
          css`
            background-color: ${COLORS.LIGHT_GREY};
            height: 2px;
          `,
        ].join(' ')}
      />

      <div className={['flex flex-col'].join(' ')}>
        <div
          className={[COLORS2_AS_FOREGROUND.main.MrtBlack, flexSpacedRowWithVerticalCenter].join(
            ' ',
          )}
        >
          <p className={font2.Headline1_Regular}>{t('fee')}</p>
          <p className={font2.Headline1_Bold}>{formatKRWWonAmount(data.rate_amount)} 원</p>
        </div>
        {data.extra_charge && (data.extra_charge.additional_amount || 0) > 0 && (
          <div
            className={[
              // 'flex flex-col gap-1.5',
              // borders.DEBUG_greenBorderDashed,
              MARGINS.TOP.spacing12,
            ].join(' ')}
          >
            <AdditionalFees2
              extra_charge={data.extra_charge}
              onSiteCurrency={data.extra_equipments_json.currency}
            />
          </div>
        )}

        <div
          id="separator"
          className={[
            MARGINS.TOP_AND_BOTTOM.spacing16,
            css`
              background-color: ${COLORS.LIGHT_GREY};
              height: 2px;
            `,
          ].join(' ')}
        />
        <div
          className={[
            flexSpacedRowWithVerticalCenter,
            // borders.DEBUG_greenBorderDashed,
          ].join(' ')}
        >
          <span className={[font2.Headline1_Bold].join(' ')}>결제하신 금액</span>

          <span className={[font2.Title2].join(' ')}>
            {formatKRWWonAmount(data.rate_amount)} 원
          </span>
        </div>
        {(data.extra_charge?.additional_amount || null) && (
          <>
            <div
              className={[
                flexSpacedRowWithVerticalCenter,
                // borders.DEBUG_goldDashed,
                css`
                  position: relative;
                `,
              ].join(' ')}
            >
              <div
                className={[
                  font2.Headline1_Bold,
                  // borders.DEBUG_goldDashed,
                  css`
                    display: flex;
                    align-items: center;
                    justify-items: center;
                    height: fit-content;
                    gap: 6px;
                  `,
                ].join(' ')}
              >
                <span>현장 결제 예상금액</span>
                <HoverToReveal
                  toReveal={
                    <div
                      className={[
                        font2.Caption1_Light,
                        PADDINGS.TOP_AND_BOTTOM.spacing16,
                        COLORS2_AS_BACKGROUND.sub.MrtGray3,
                        css`
                          position: absolute;
                          left: 0;
                          right: 0;
                          top: 100%;
                          padding-left: 10px;
                          padding-right: 10px;
                          border-radius: 10px;
                        `,
                      ].join(' ')}
                    >
                      {PPDOnsitePaymentHoverMessage}
                    </div>
                  }
                />
              </div>

              <p className={[font2.Title2].join(' ')}>
                {formatKRWWonAmount(data.extra_charge?.additional_amount, 0.000001)}
                &nbsp;
                {data.paymentDetails.onSiteCurrency}
              </p>
            </div>
            <p
              className={[
                font2.Caption1_Light,
                flexRightmostItemRow,
                COLORS2_AS_FOREGROUND.sub.MrtGray1,
              ].join(' ')}
            >
              약 &nbsp;
              {formatKRWWonAmount(data.extra_charge?.additional_rate_amount)}
              &nbsp;
              {t('won')}
            </p>
          </>
        )}
      </div>

      <div
        id="separator3"
        className={[
          MARGINS.TOP_AND_BOTTOM.spacing16,
          css`
            background-color: ${COLORS.LIGHT_GREY};
            height: 2px;
          `,
        ].join(' ')}
      />
      <div className={[flexSpacedRowWithVerticalCenter].join(' ')}>
        <p className={font2.Headline1_Regular}>결제 수단</p>
        <p className={font2.Headline1_Bold}>신용카드</p>
      </div>

      <div className={[flexSpacedRowWithVerticalCenter, MARGINS.TOP.spacing12].join(' ')}>
        <p className={font2.Headline1_Regular}>결제 일시</p>
        <p className={font2.Headline1_Bold}>{paymentTime}</p>
      </div>

      <div className={[flexSpacedRowWithVerticalCenter, MARGINS.TOP.spacing12].join(' ')}>
        <p className={font2.Headline1_Regular}>취소 일시</p>
        <p className={font2.Headline1_Bold}>{cancelTime}</p>
      </div>

      <div
        id="separator2"
        className={[
          css`
            margin-top: ${SPACING.spacing16};
            margin-bottom: ${SPACING.spacing16};
            background-color: ${COLORS.LIGHT_GREY};
            height: 2px;
          `,
        ].join(' ')}
      />

      <div
        className={[
          flexSpacedRowWithVerticalCenter,
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
      >
        <span className={[font2.Headline1_Bold].join(' ')}>환불 금액</span>

        <span className={[font2.Title2].join(' ')}>{formatKRWWonAmount(data.rate_amount)} 원</span>
      </div>

      <div
        className={[
          flexSpacedRowWithVerticalCenter,
          // borders.DEBUG_blueBorderDashed,
        ].join(' ')}
      >
        <span className={[font2.Headline1_Bold].join(' ')}>취소 수수료</span>

        <span className={[font2.Title2].join(' ')}>0 원</span>
      </div>
      <div
        className={[
          font2.Caption2_Light,
          COLORS2_AS_FOREGROUND.main.MrtBlack,
          // borders.DEBUG_greenBorderDashed,
          MARGINS.TOP.spacing6,
          css`
            text-align: center;
            white-space: pre-line;
          `,
        ].join(' ')}
      >
        {`취소 시 환불은 카드사를 통해 2~5일 내 환불될 예정입니다.\n실제 적용되는 취소수수료 및 환불금액과 다르게 보일 수 있습니다.`}
      </div>
    </div>
  );
});
