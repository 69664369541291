import React from 'react';

import { Disclosure } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

// import { actions, sendTrackingLog } from '^/api/trackingLog';
import DynamicIcon from '^/components/Icon';

export const getTermsAndConditionCancellationRichContent = () => {
  return (
    <>
      <p className="text-default font-light tablet-max:text-small">
        트리모를 통해 예약한 경우, 트리모 취소 및 환불 규정에 따라 환불이 진행됩니다. 예약 취소
        시점에 따라 다음과 같이 취소 수수료를 제한 후 환불이 진행될 수 있습니다.
      </p>
      <p className="mt-3 text-default font-light tablet-max:text-small">
        <p className="font-bold">예약 변경</p>
        <p className="mt-2">- 예약 변경은 취소 후 재 예약하셔야 합니다.</p>
        <br />
        <p className="font-bold">예약 취소</p>
        <p className="mt-2">
          - 취소는 반드시 트리모를 통해 진행하셔야 합니다. 다른 경로(타 홈페이지 또는 고객센터 등)를
          통해 취소할 경우 불이익이 발생할 수 있습니다.
          <br />- 픽업 시간의 한국 시간 기준, 수수료가 발생할 수 있습니다. <br />
          <br />
          <p className="text-default font-bold text-[#f00] tablet-max:text-small">
            1) 픽업 72시간 이전 취소 시: 없음
          </p>
          <p className="text-default font-bold text-[#f00] tablet-max:text-small">
            2) 픽업 72시간 이내 취소 시: 결제 금액의 30%에 해당하는 취소 수수료 발생
          </p>
          <p className="text-default font-bold text-[#f00] tablet-max:text-small">
            3) 픽업 시간 이후 취소 시 : NO-SHOW로 간주되며, 결제 금액의 100%에 해당하는 취소 수수료
            발생, 환불 불가 (필수 지참물 미소지 등 임차인의 귀책사유로 인해 대여가 불가능한 경우에도
            동일하게 적용됩니다.)
          </p>
          <br />
          * NO-SHOW: 사전 취소 없이 차량을 픽업하지 않는 경우를 말합니다.
          <br />* 필수 지참물 : 여권, 운전자 본인 명의 신용카드, 국내 면허증, 국제 면허증 (모바일 및
          사본 불가)
        </p>
      </p>
    </>
  );
};

export const TermsAndConditionCancellationRich = () => {
  const { t } = useTranslation();
  return (
    <Disclosure>
      {({ open }) => (
        <div className="flex w-full flex-col mobile:gap-5 tablet:gap-6">
          <Disclosure.Button
            className="flex items-center justify-between text-big tablet-max:text-default tablet-max:font-light"
            // onClick={() => {
            //   const action = open
            //     ? actions.DRIVER_DETAIL.CLOSE_TEMRS_OF_CANCELLATION
            //     : actions.DRIVER_DETAIL.OPEN_TERMS_OF_CANCELLATION;
            //   sendTrackingLog({
            //     action,
            //   });
            // }}
          >
            <span className="text-[16px] tablet-max:text-default">{t('cancelAndRefund')}</span>
            <DynamicIcon name={open ? 'FiChevronUp' : 'FiChevronDown'} />
          </Disclosure.Button>
          <Disclosure.Panel className="term-content max-h-[300px] overflow-y-auto bg-trimo-shadow p-6 tablet-max:p-4">
            {getTermsAndConditionCancellationRichContent()}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};
