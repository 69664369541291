import { MobxInputValue } from '^/util/MobxInputValue';

import { CancelButtonStyle } from '../../desktop/components/ReservationDetailDesktop.commonStuff';

const BillCancelButton = ({ data, setShowCancelConfirmPopup, isCancelDisabled }) => {
  return (
    <>
      <button
        type="button"
        onClick={() => {
          setShowCancelConfirmPopup(new MobxInputValue(true));
        }}
        disabled={isCancelDisabled}
        className={`${CancelButtonStyle}`}
      >
        예약 취소하기
      </button>
    </>
  );
};

export default BillCancelButton;
